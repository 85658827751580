import React, {useCallback, useContext, useEffect, useState} from 'react'
import {CAlert} from '@coreui/react-pro'
import RowCol from '../components/util/RowCol'

export const ErrorContext = React.createContext({})

export const useError = () => useContext(ErrorContext)

export const RequestCancelled = 'Cancelled due to stale request'

export const useErrorLocal = () => {
  const [error, setError] = useState(undefined)

  const handleError = useCallback(async (body) => {
    try {
      const result = await body()
      setError(undefined)
      return result
    } catch (e) {
      // Don't want to show this one.
      if (e.message === RequestCancelled) return
      if (e.response) {
        setError(handleResponse(e))
      } else {
        console.error('Error!', e)
        setError(`Unexpected error processing request: ${e.message}`)
      }
    }
  }, [])

  const clearError = () => setError(null)
  const renderErrorAlert = it => <CAlert color="danger">{it}</CAlert>
  const errorAlert = error ? renderErrorAlert(error) : undefined

  return {errorAlert, setError, handleError, clearError}
}

export const ErrorProvider = ({children}) => {
  const {errorAlert, handleError, setError, clearError} = useErrorLocal()

  return (
    <ErrorContext.Provider value={{handleError, setError, clearError}}>
      {errorAlert && <RowCol className="text-center">{errorAlert}</RowCol>}
      {children}
    </ErrorContext.Provider>
  )
}

function handleResponse(e) {
  if (e.response.data && e.response.data._embedded && e.response.data._embedded.errors) {
    console.error('Error!', e.response.data._embedded.errors)
    const [error] = e.response.data._embedded.errors
    return (error && error.message) || 'Unknown error'
  } else if (typeof e.response.data === 'string' && e.response.data) {
    console.info(e.response.data)
    return e.response.data
  } else {
    console.error('Error!', e.response)
    return e.message
  }
}
